// src/api/endpoints/exampleEndpoint.js
import {
  ACCEPT_PRIVACY_POLICY_CHANGES_URL,
  ADD_MEDIA_URL,
  CHECK_PRIVACY_POLICY_CONSENT_STATUS,
  DELETE_ACCOUNT_URL,
  DELETE_MEDIA_URL,
  FEEDBACK_URL,
  FETCH_PRIVACY_POLICY_URL,
  GET_PRIVACY_CONSENT_URL,
  GET_PRIVACY_POLICY,
  GET_TERMS_AND_CONDITIONS,
  GET_VISIBILITY_SETTINGS,
  SET_PRIVACY_CONSENT_URL,
  UPDATE_VISIBILITY_SETTINGS
} from "../../constants/endpoints"
import { apiSlice } from "../apiSlice"

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updatePrivacyConsentSettings: builder.mutation({
      query: data => ({
        url: SET_PRIVACY_CONSENT_URL,
        method: "POST",
        body: data
      })
    }),
    getPrivacyConsentSettings: builder.query({
      query: () => ({
        url: GET_PRIVACY_CONSENT_URL
      })
    }),
    deleteUserAccount: builder.mutation({
      query: () => ({
        url: DELETE_ACCOUNT_URL,
        method: "DELETE"
      })
    }),
    checkPrivacyPolicyConsentStatus: builder.query({
      query: () => ({
        url: CHECK_PRIVACY_POLICY_CONSENT_STATUS
      })
    }),
    fetchPrivacyPolicy: builder.query({
      query: () => ({
        url: FETCH_PRIVACY_POLICY_URL
      })
    }),
    acceptPrivacyPolicyChanges: builder.mutation({
      query: () => ({
        url: ACCEPT_PRIVACY_POLICY_CHANGES_URL,
        method: "PUT"
      })
    }),
    submitFeedback: builder.mutation({
      query: body => ({
        url: FEEDBACK_URL,
        method: "POST",
        body: body
      })
    }),
    addMedia: builder.mutation({
      query: formData => ({
        url: ADD_MEDIA_URL,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    }),
    fetchVisibilitySettings: builder.query({
      query: () => ({
        url: GET_VISIBILITY_SETTINGS
      })
    }),
    updateVisibilitySettings: builder.mutation({
      query: data => ({
        url: UPDATE_VISIBILITY_SETTINGS,
        method: "PATCH",
        body: data
      })
    }),
    deleteMedia: builder.mutation({
      query: data => ({
        url: DELETE_MEDIA_URL,
        method: "DELETE",
        body: data
      })
    }),
    getPrivacyPolicy: builder.query({
      query: () => ({
        url: GET_PRIVACY_POLICY
      })
    }),
    getTermsAndConditions: builder.query({
      query: () => ({
        url: GET_TERMS_AND_CONDITIONS
      })
    })
  }),
  overrideExisting: false
})

export const {
  useUpdatePrivacyConsentSettingsMutation,
  useGetPrivacyConsentSettingsQuery,
  useDeleteUserAccountMutation,
  useCheckPrivacyPolicyConsentStatusQuery,
  useFetchPrivacyPolicyQuery,
  useAcceptPrivacyPolicyChangesMutation,
  useSubmitFeedbackMutation,
  useAddMediaMutation,
  useFetchVisibilitySettingsQuery,
  useUpdateVisibilitySettingsMutation,
  useDeleteMediaMutation,
  useGetPrivacyPolicyQuery,
  useGetTermsAndConditionsQuery
} = settingsApiSlice
