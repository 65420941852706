import { BrowserRouter as Router } from "react-router-dom"
import "./styles/base/_variables.scss"
import "./styles/base/normalize.scss"
import "./styles/base/_fonts.scss"
import "./styles/base/_typography.scss"
import "./styles/base/__globalStyles.scss"
import AppRoutes from "./routes"
import { ConfigProvider } from "antd"
import { theme } from "./theme"

const App = () => {
  return (
    <Router>
      <ConfigProvider theme={theme}>
      <AppRoutes />
      </ConfigProvider>
    </Router>
  )
}

export default App
