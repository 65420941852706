import { Layout } from "antd"
import React from "react"
import { Outlet } from "react-router-dom"
import HeaderNav from "../../components/HeaderNav/HeaderNav"
const { Content } = Layout

export default function FlexTimeLayout() {
  return (
    <Layout>
      <HeaderNav />
      <Content className="ContentContainer">
        <Outlet />
      </Content>
    </Layout>
  )
}
