import "./styles.scss"
import SettingsSection from "../components/settings-section/SettingsSection"
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import GoToButton from "../../../components/go-to-button"
import { Modal } from "antd"
import { useDeleteUserAccountMutation } from "../../../api/apiSlices/settingsApiSlice"
import { removeJWTToken } from "../../../api/auth"
import { useNavigate } from "react-router-dom"

const AccountDeletion = () => {
  const [modal, contextHolder] = Modal.useModal()
  const [deleteAccount] = useDeleteUserAccountMutation()
  let navigate = useNavigate()

  const deleteAccountPermanently = () => {
    deleteAccount().then(() => {
      removeJWTToken()
      navigate("/info", {
        state: {
          title: "Account has been deleted",
          message: "Your account has been deleted permanently."
        }
      })
    })
  }

  const showModal = () => {
    modal.confirm({
      title: "Delete Account",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to delete this account? This action cannot be undone.",
      okText: "Remove",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: deleteAccountPermanently,
      centered: true
    })
  }

  return (
    <div className="tabContainer">
      <SettingsSection
        title="Delete Account"
        description="Permanently delete your account and all associated data."
      >
        <div className="actionSection">
          <div className="deleteBtnWrapper">
            <GoToButton
              title="Delete Account"
              icon={DeleteOutlined}
              variant="danger"
              onClick={showModal}
            />
          </div>
        </div>
      </SettingsSection>
      {contextHolder}
    </div>
  )
}

export default AccountDeletion
