import React from "react"
import { Avatar, Button, Flex, Typography } from "antd"

import "./suggestionsList.scss"

const { Text, Title } = Typography

const defaultFn = () => ""

function SuggestionsListItem({
  name = "",
  username = "",
  user_id = null,
  onAddFriend = defaultFn
}) {
  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      className="single-list-item"
    >
      <Flex gap="middle" align="center">
        <Avatar size={40} />
        <Flex vertical className="single-item-content">
          <Title level={5}>{name}</Title>
          <Text>@{username}</Text>
        </Flex>
      </Flex>
      <Button type="link" primary onClick={() => onAddFriend(user_id)}>
        Add Friend
      </Button>
    </Flex>
  )
}

export default SuggestionsListItem
