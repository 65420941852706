import { notification } from "antd"

export const openNotification = ({
  type = "info",
  message,
  description,
  placement,
  style,
  duration,
  icon
}) => {
  notification[type]({
    message,
    description,
    style,
    placement,
    duration,
    icon
  })
}
