import Info from "./components/info/Info"
import { UserOutlined } from "@ant-design/icons"
import { Avatar, Typography, Flex, Button, Col } from "antd"
import Title from "antd/es/typography/Title"
import styles from "./profile.module.scss"
import UserConnectionManagment from "./components/user-connection/UserConnectionManagment"
import PostCard from "../social-media/components/postCard/postCard"
import SuggestionsList from "../social-media/components/suggestionsList/suggestionsList"

import {
  useAddCommentToPostMutation,
  useGetUserPostsQuery,
  useTogglePostLikeMutation
} from "../../api/apiSlices/socialMediaApiSlice"

import { AddUserIcon, BubbleChatIcon, LockIcon } from "../../assets/rawSvgs"

const { Text } = Typography

const Profile = () => {
  const {
    data: userPosts,
    isLoading: isUserPostsLoading,
    refetch: refetchUserPosts
  } = useGetUserPostsQuery()

  const [
    togglePostLike,
    { isLoading: isTogglePostLikeLoading, error: togglePostLikeError }
  ] = useTogglePostLikeMutation()

  const [
    addCommentToPost,
    { isLoading: isAddCommentToPostLoading, error: addCommentToPostError }
  ] = useAddCommentToPostMutation()

  return (
    <div className={styles.ProfileContainer}>
      <Flex justify="space-between" align="start">
        <Flex gap="large">
          <Avatar size={100} icon={<UserOutlined />} />
          <Flex vertical gap="large">
            <div>
              <Title level={5}>Kianna Donin</Title>
              <Flex align="center" gap={12}>
                <Text type="secondary" className="text-lg-400">
                  @coryrobbins
                </Text>
                <div className="dot dot_small"></div>
                <Text type="secondary" className="text-lg-400">
                  Age:20
                </Text>
                <div className="dot dot_small"></div>
                <Text type="secondary" className="text-lg-400">
                  Female
                </Text>
              </Flex>
            </div>
            <UserConnectionManagment />
          </Flex>
        </Flex>
        <Flex gap={12} align="center">
          <Button
            htmlType="text"
            // type="primary"
            size="large"
            icon={<AddUserIcon />}
          >
            Add Friend
          </Button>
          <Button
            htmlType="text"
            size="large"
            icon={<BubbleChatIcon color="rgba(0, 0, 0, 0.88)" />}
          >
            Message
          </Button>
        </Flex>
      </Flex>
      <Flex vertical>
        <Text style={{ fontSize: "16px" }}>Check out my latest adventure!</Text>
        <Text style={{ fontSize: "16px" }}>Check out my latest adventure!</Text>
        <Text style={{ fontSize: "16px" }}>Check out my latest adventure!</Text>
        <Text style={{ fontSize: "16px" }}>Check out my latest adventure!</Text>
      </Flex>
      <div className={styles.profileScreenScroll}>
        {/* <Flex gap="middle" wrap="wrap">
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
          <Avatar
            style={{ borderRadius: "0" }}
            shape="square"
            size={210}
            icon={<UserOutlined />}
          />
        </Flex> */}
        {/* <div className={styles.lockCard}>
          <LockIcon />
          <Text className="text-lg-500">Kianna Locked her Profile</Text>
        </div> */}

        <Flex gap="48px">
          <Col className="gutter-row" span={16}>
            {userPosts?.map(feed => {
              return (
                <PostCard
                  index={feed?.post_id}
                  attachments={feed?.attachments}
                  author={feed?.author}
                  comments={feed?.comments}
                  comments_count={feed?.comments_count}
                  comments_enabled={feed?.comments_enabled}
                  content={feed?.content}
                  created_at={feed?.created_at}
                  likes_count={feed?.likes_count}
                  post_id={feed?.post_id}
                  likes_enabled={feed?.likes_enabled}
                  user_has_liked={feed?.user_has_liked}
                  likes={feed?.likes}
                  togglePostLike={togglePostLike}
                  addCommentToPost={addCommentToPost}
                  isAddCommentToPostLoading={isAddCommentToPostLoading}
                />
              )
            })}
          </Col>
          <Col className="gutter-row" span={8}>
            <SuggestionsList />
          </Col>
        </Flex>
      </div>
    </div>
  )
}

export default Profile
