import React from "react"
import { useParams } from "react-router-dom"
import { Button, Typography } from "antd"
import { useFormik } from "formik"

import CustomInput from "../../components/customInput/CustomInput"
import useRouterNavigation from "../../hooks/useNavigate"
import { passwordValidationSchema } from "../../schemas/ResetValidationSchema"

import { useResetPasswordMutation } from "../../api/apiSlices/authApiSlice"

import { Routes } from "../../constants/routes"

import styles from "../login/auth.module.scss"
import { openNotification } from "../../util/openNotifications"

const { Title, Text } = Typography

const ResetPassword = () => {
  const { navigateTo } = useRouterNavigation()

  const [
    resetPassword,
    { isLoading: isResetPasswordLoading, error: resetPasswordError }
  ] = useResetPasswordMutation()

  const { id, token } = useParams()

  const { values, setValues, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: { new_password: "", confirm_password: "" },
      validationSchema: passwordValidationSchema,
      onSubmit: async values => {
        try {
          await resetPassword({ ...values, id, token }).unwrap()
          openNotification({
            type: "success",
            message: "Your Password is reset successfully"
          })
          navigateTo(Routes.LOGIN)
        } catch (e) {
          console.log("e", e)
        }
      }
    })

  return (
    <div className={styles.AuthContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.AuthWrapper} style={{ height: "458px" }}>
          <div className={`${styles.forgotContent}`}>
            <div className="text-center mb-32">
              <Title level={3} className="mb-2">
                Reset Password
              </Title>
              <Text>Create your new password here.</Text>
            </div>
            <CustomInput
              inputwd="mb-3"
              value={values?.new_password}
              onChange={e =>
                setValues({ ...values, new_password: e.target.value })
              }
              type="password"
              label="New Password"
              placeholder="Password"
              errorMessage={errors?.new_password}
            />
            <CustomInput
              value={values?.confirm_password}
              onChange={e =>
                setValues({ ...values, confirm_password: e.target.value })
              }
              type="password"
              label="Confirm Password"
              placeholder="Password"
              errorMessage={errors?.confirm_password}
            />
          </div>
          <Button
            htmlType="submit"
            disabled={isResetPasswordLoading}
            type="primary"
            size="large"
            block
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
