import React from "react"
import { Select } from "antd"

const defaultFn = () => ""

const CustomSelect = ({
    selectWd = "",
    label = "",
    options = [],
    value = "",
    placeholder = "",
    handleChange = defaultFn
}) => {
    return (
        <div className={`${selectWd} d-flex flex-column`}>
            {label && <label className={`Select_label`}>{label}</label>}
            <Select
                placeholder={placeholder}
                // style={{
                //     width: 120,
                // }}
                value={value}
                onChange={handleChange}
                options={options}
            />
        </div>
    )
}

export default CustomSelect
