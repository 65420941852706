import { Routes } from "../constants/routes"
import Profile from "../pages/profile/Profile"
import Settings from "../pages/settings"
import SocialMedia from "../pages/social-media"
import UserExperience from "../pages/user-experience/UserExperience"
import WorkerProfile from "../pages/worker-profile"

export const common = [
  {
    path: Routes.SETTINGS,
    component: Settings
  }
]

export const socialMediaRoutes = [
  {
    path: Routes.PROFILE,
    component: Profile
  },
  {
    path: Routes.HOME,
    component: SocialMedia
  },
  ...common
]

export const flexTimeRoutes = [
  {
    path: Routes.USER_EXPERIENCE,
    component: UserExperience
  },
  {
    path: Routes.WORKER_PROFILE,
    component: WorkerProfile
  },
  ...common
]
