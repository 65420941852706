import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ConfigProvider } from "antd"
import { GoogleOAuthProvider } from "@react-oauth/google"
import "bootstrap/dist/css/bootstrap.min.css"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

import { store } from "./redux/store"
import "./index.css"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "3DC1DC"
          }
        }}
      >
        <GoogleOAuthProvider clientId="579589168820-r2jo1t17dll5u2ssddqvrsc3t8p29no8.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
