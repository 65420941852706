import React, { useState } from "react"
import { Button, Modal } from "antd"

const CustomModal = props => {
  const {
    ModalTrigger,
    title,
    ModalBody,
    modalBodyClass,
    handleOk,
    handleCancel,
    showModal,
    open,
    primeBtnText,
    isDisabled,
    
  } = props

  return (
    <>
      <span onClick={showModal}>{ModalTrigger}</span>
      <Modal
        className="CustomModalWrapper"
        open={open}
        centered
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={isDisabled}
          >
            {primeBtnText}
          </Button>
        ]}
      >
        <div className={modalBodyClass}>{ModalBody}</div>
      </Modal>
    </>
  )
}

export default CustomModal
