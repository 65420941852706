export const LOGIN_URL = "/api/v1/login/"
export const FACEBOOK_SSO_LOGIN_URL = "/api/v1/social-auth/facebook/login/"
export const GOOGLE_SSO_LOGIN_URL = "/api/v1/social-auth/google/login/"
export const REGISTER_URL = "/api/v1/signup/"
export const VERIFY_OTP_URL = "/api/v1/verify-otp/"
export const RESEND_OTP_URL = "/api/v1/resend-otp/"
export const FORGOT_PASSWORD_URL = "/api/v1/password-reset/"
export const RESET_PASSWORD_URL = "/api/v1/password-reset-confirm/:id/:token/"
export const GET_PRIVACY_CONSENT_URL = "/api/v1/consent/"
export const SET_PRIVACY_CONSENT_URL = "/api/v1/consent/"
export const DELETE_ACCOUNT_URL = "/api/v1/account/delete-account/"
export const CHECK_PRIVACY_POLICY_CONSENT_STATUS =
  "/api/v1/policy/check-consent/"
export const FETCH_PRIVACY_POLICY_URL = "/api/v1/get-privacy-policy/"
export const ACCEPT_PRIVACY_POLICY_CHANGES_URL =
  "/api/v1/policy/accept-consent/"
export const FEEDBACK_URL = "/api/v1/feedback/"
export const ADD_MEDIA_URL = "/api/v1/file-upload/"
export const GET_VISIBILITY_SETTINGS = "/api/v1/privacy-settings/"
export const UPDATE_VISIBILITY_SETTINGS = "/api/v1/privacy-settings/"
export const DELETE_MEDIA_URL = "/api/v1/file-delete/"
export const GET_SOCIAL_FEED = "/api/v1/social-media/feed/"
export const GET_USER_CONNECTIONS = "/api/v1/social-media/user-connections/"
export const CREATE_SOCIAL_MEDIA_POST = "/api/v1/social-media/posts/"
export const DELETE_USER_CONNECTION =
  "/api/v1/social-media/user-connections/:id/"
export const GET_USER_SUGGESTIONS =
  "/api/v1/social-media/user-connections/suggestions/"
export const ADD_USER_CONNECTIONS = "/api/v1/social-media/user-connections/"
export const TOGGLE_POST_LIKE = "/api/v1/social-media/posts/:postId/like/"
export const ADD_POST_COMMENT = "/api/v1/social-media/posts/:postId/comments/"
export const GET_WORKER_INFO_URL = "/api/v1/get-worker-profile-information/"
export const SAVE_WORKER_PROFILE_INFO = "/api/v1/create-worker-profile/"
export const SAVE_WORKER_EXPERIENCE = "/api/v1/add-work-experience/"
export const DELETE_WORK_EXPERIENCE = "/api/v1/add-work-experience/:id/"
export const ADD_WORKER_SKILL = "/api/v1/add-skill/"
export const ADD_CUSTOM_AVAILABILITY = "/api/v1/set-custom-availability/"
export const ADD_RECURRING_AVAILABILITY = "/api/v1/set-weekly-availability/"
export const FINISH_ONBOARDING = "/api/v1/set-worker-onboarding-flag-true/"
export const GET_WORKER_PROFILE = "/api/v1/get-worker-profile-information/"
export const GET_PRIVACY_POLICY = "/api/v1/get-privacy-policy/"
export const GET_TERMS_AND_CONDITIONS = "/api/v1/get-terms-conditions/"
export const GET_USER_POSTS = "/api/v1/social-media/posts/"
export const GET_COUNTRIES = "/api/v1/countries/"
export const EDIT_WORKER_PROFILE = "/api/v1/edit-worker-profile/"
