import {
  ADD_CUSTOM_AVAILABILITY,
  ADD_RECURRING_AVAILABILITY,
  ADD_WORKER_SKILL,
  DELETE_WORK_EXPERIENCE,
  GET_WORKER_INFO_URL,
  SAVE_WORKER_EXPERIENCE,
  SAVE_WORKER_PROFILE_INFO
} from "../../constants/endpoints"
import { apiSlice } from "../apiSlice"

export const workerApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateWorkerProfileInfo: builder.mutation({
      query: data => ({
        url: SAVE_WORKER_PROFILE_INFO,
        method: "POST",
        body: data
      })
    }),
    getWorkerProfileInfo: builder.query({
      query: () => ({
        url: GET_WORKER_INFO_URL
      })
    }),
    updateWorkerExperience: builder.mutation({
      query: data => ({
        url: SAVE_WORKER_EXPERIENCE,
        method: "POST",
        body: data
      })
    }),
    deleteWorkExpereience: builder.mutation({
      query: id => ({
        url: DELETE_WORK_EXPERIENCE.replace(":id", id),
        method: "DELETE"
      })
    }),
    updateWorkerSkills: builder.mutation({
      query: data => ({
        url: ADD_WORKER_SKILL,
        method: "POST",
        body: data
      })
    }),
    addCustomAvailability: builder.mutation({
      query: data => ({
        url: ADD_CUSTOM_AVAILABILITY,
        method: "POST",
        body: data
      })
    }),
    addRecurringAvailability: builder.mutation({
      query: data => ({
        url: ADD_RECURRING_AVAILABILITY,
        method: "POST",
        body: data
      })
    }),
    finishOnboarding: builder.mutation({
      query: data => ({
        url: ADD_RECURRING_AVAILABILITY,
        method: "POST",
        body: data
      })
    })
  }),
  overrideExisting: false
})

export const {
  useUpdateWorkerProfileInfoMutation,
  useGetWorkerProfileInfoQuery,
  useUpdateWorkerExperienceMutation,
  useDeleteWorkExpereienceMutation,
  useUpdateWorkerSkillsMutation,
  useAddCustomAvailabilityMutation,
  useAddRecurringAvailabilityMutation,
  useFinishOnboardingMutation
} = workerApiSlice
