import "./styles.scss"
import SettingsSection from "../components/settings-section/SettingsSection"
import { Spin, Switch, Typography } from "antd"
import {
  useGetPrivacyConsentSettingsQuery,
  useUpdatePrivacyConsentSettingsMutation
} from "../../../api/apiSlices/settingsApiSlice"

const ConsentManagement = () => {
  const { data, refetch, isLoading } = useGetPrivacyConsentSettingsQuery()
  const [updateConsent] = useUpdatePrivacyConsentSettingsMutation()

  const onChangeStatus = async (key, value) => {
    await updateConsent({ [key]: value })
    refetch()
  }
  return (
    <div className="tabContainer">
      <Spin spinning={isLoading}>
        <SettingsSection title="Data Collection Consent">
          <div className="actionSection">
            <Typography>
              Consent to AGP collecting data about your activity, preferences,
              and interactions.
            </Typography>
            <Switch
              defaultChecked
              value={data?.data_collection_consent}
              onChange={value =>
                onChangeStatus("data_collection_consent", value)
              }
            />
          </div>
        </SettingsSection>
        <SettingsSection title="Third-Party Data Sharing">
          <div className="actionSection">
            <Typography>Share Data with Third Parties</Typography>
            <Switch
              value={data?.third_party_data_sharing}
              onChange={value =>
                onChangeStatus("third_party_data_sharing", value)
              }
            />
          </div>
        </SettingsSection>
      </Spin>
    </div>
  )
}

export default ConsentManagement
