import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false,
  error: "",
  isOpenCreatePost: false
}

const slice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {
    setIsOpenCreatePost(state, action) {
      state.isOpenCreatePost = action.payload
    }
  }
})

export const { setIsOpenCreatePost } = slice.actions
// Reducer
export default slice.reducer
