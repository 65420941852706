import { ArrowRightOutlined } from "@ant-design/icons"
import "./styles.scss"

export default function GoToButton({
  icon: Icon = null,
  title,
  variant = "",
  onClick = () => {}
}) {
  return (
    <div className={`goToBtn ${variant}`} onClick={onClick}>
      {Icon && <Icon />}
      <span className="title">{title}</span>
      <ArrowRightOutlined className="arrowIcon" />
    </div>
  )
}
