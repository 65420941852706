import React, { useState } from "react"
import { Spin, Steps } from "antd"
import CreateProfile from "./components/CreateProfile"
import ProfessionalExperience from "./components/ProfessionalExperience"
import Skills from "./components/Skills"
import Availability from "./components/Availability"
import { useFinishOnboardingMutation } from "../../api/apiSlices/workerApiSlice"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../constants/routes"
import "./styles.scss"

const UserExperience = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const onChangeStep = step => setCurrentStep(step)
  const [finishOnboarding, { isLoading }] = useFinishOnboardingMutation()
  let navigate = useNavigate()

  const onNextClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const onPreviousClick = () => {
    setCurrentStep(currentStep - 1)
  }

  const onFinishOnboarding = () => {
    finishOnboarding().then(() => {
      navigate(Routes.WORKER_PROFILE)
    })
  }
  const handleSkip = () => {
    onFinishOnboarding()
  }

  return (
    <div className="userExperience">
      <Spin spinning={isLoading}>
        <Steps
          current={currentStep}
          items={[
            {
              title: "Create your profile"
            },
            {
              title: "Professional Experience"
            },
            {
              title: "Skills"
            },
            {
              title: "Availability"
            }
          ]}
          onChange={onChangeStep}
        />
        {currentStep === 0 && (
          <CreateProfile
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            handleSkip={handleSkip}
          />
        )}
        {currentStep === 1 && (
          <ProfessionalExperience
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        )}
        {currentStep === 2 && (
          <Skills
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        )}
        {currentStep === 3 && (
          <Availability
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            onFinishOnboarding={onFinishOnboarding}
          />
        )}
      </Spin>
    </div>
  )
}

export default UserExperience
