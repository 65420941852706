import { configureStore } from "@reduxjs/toolkit"
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector
} from "react-redux"
import { persistStore } from "redux-persist"
import { rootReducer } from "./rootReducer"
import { apiSlice } from "../api/apiSlice"

const store = configureStore({
  reducer: rootReducer,
  // devTools: false,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(apiSlice.middleware)
})

const persistor = persistStore(store)

const { dispatch } = store

const useSelector = useAppSelector

const useDispatch = () => useAppDispatch()

export { store, persistor, dispatch, useSelector, useDispatch }
