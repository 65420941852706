import React from "react"
import { Col, Empty, Flex } from "antd"

import PostCard from "./components/postCard/postCard"
import SuggestionsList from "./components/suggestionsList/suggestionsList"

import {
  useAddCommentToPostMutation,
  useGetSocialFeedQuery,
  useTogglePostLikeMutation
} from "../../api/apiSlices/socialMediaApiSlice"

import { useDispatch } from "react-redux"

const SocialMedia = () => {
  const {
    data: socialFeed,
    isLoading: isSocialFeedLoading,
    refetch: refetchSocialFeed
  } = useGetSocialFeedQuery()

  const [
    togglePostLike,
    { isLoading: isTogglePostLikeLoading, error: togglePostLikeError }
  ] = useTogglePostLikeMutation()

  const [
    addCommentToPost,
    { isLoading: isAddCommentToPostLoading, error: addCommentToPostError }
  ] = useAddCommentToPostMutation()

  return (
    <div className="socialMediaScreen">
      <Flex gap="48px">
        <Col className="gutter-row" span={16}>
          <div className="socialMediaScroll">
            {socialFeed?.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                vertical
                style={{ height: "100%" }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            ) : (
              socialFeed?.map(feed => {
                return (
                  <PostCard
                    index={feed?.post_id}
                    attachments={feed?.attachments}
                    author={feed?.author}
                    comments={feed?.comments}
                    comments_count={feed?.comments_count}
                    comments_enabled={feed?.comments_enabled}
                    content={feed?.content}
                    created_at={feed?.created_at}
                    likes_count={feed?.likes_count}
                    post_id={feed?.post_id}
                    likes_enabled={feed?.likes_enabled}
                    user_has_liked={feed?.user_has_liked}
                    likes={feed?.likes}
                    togglePostLike={togglePostLike}
                    addCommentToPost={addCommentToPost}
                    isAddCommentToPostLoading={isAddCommentToPostLoading}
                  />
                )
              })
            )}
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <SuggestionsList />
        </Col>
      </Flex>
    </div>
  )
}

export default SocialMedia
