export const theme = {
  components: {
    Button: {
      colorPrimary: "rgb(61,193,220)",
      colorPrimaryActive: "rgb(61,193,220)"
    },
    Typography: {
      titleMarginBottom: "0",
      titleMarginTop: "0",
      colorTextDescription: "rgba(0,0,0,0.65)",
      fontWeightStrong: "700"
    },
    Layout: {
      siderBg: "rgb(255,255,255)",
      headerBg: "rgb(255,255,255)",
      headerHeight: 80,
      bodyBg: "rgb(255,255,255)",
      footerPadding: "64px 32px",
      footerBg: "rgb(19,23,68)"
    },
    Checkbox: {
      borderRadiusSM: 4
    },
    Form: {
      labelRequiredMarkColor: "rgb(245,34,45)"
    },
    Input: {
      controlHeight: 40,
      controlHeightLG: 32
    },
    Select: {
      controlHeight: 40,
      controlHeightLG: 32,
      optionHeight: 40,
      optionPadding: "9px 24px"
    },
    Switch: {
      trackHeight: 20,
      handleSize: 16,
      trackMinWidth: 36
    },
    Modal: {
      borderRadiusLG: 8
    },
    Tabs: {
      horizontalMargin: "0 0 24px 0"
    },
    Dropdown: {
      controlPaddingHorizontal: 24,
      paddingBlock: 9
    },
    Menu: {
      activeBarBorderWidth: 0
    },
    Steps: {
      colorText: "rgb(0,0,0)",
      padding: 8
    },
    Divider: {
      colorSplit: "rgb(240,240,240)"
    },
    Card: {
      borderRadiusLG: 8
    },
    Badge: {
      statusSize: 10,
      fontSize: 10,
      dotSize: 14
      // indicatorHeight: 28
    },
    List: {
      avatarMarginRight: 12,
      itemPadding: "24px 0"
    },
    Empty: {
      colorTextDescription: "rgba(0,0,0,0.25)"
    },
    Table: {
      cellPaddingBlock: 12,
      cellPaddingInline: 8,
      headerBg: "rgba(0,0,0,0.02)"
    }
  },
  token: {
    colorPrimary: "#3dc1dc",
    colorInfo: "#3dc1dc",
    colorError: "#cf1322",
    borderRadius: 8
  }
}
