import * as Yup from "yup"

export const registerValidationSchema = Yup.object({
  name: Yup.string().required("name is required"),
  username: Yup.string().required("username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required")
})
