import { createApi } from "@reduxjs/toolkit/query/react"

import { main_api as axiosInstance } from "./axios_helper"

const axiosBaseQuery = () => async args => {
  try {
    const result = await axiosInstance({
      url: args.url,
      method: args.method || "GET",
      data: args.body,
      params: args.params
    })

    return { data: result.data.data, message: result.data.message }
  } catch (axiosError) {
    let errMsg = axiosError.message
    if (axiosError.response) {
      errMsg =
        axiosError.response.data.message || axiosError.response.statusText
    }
    return { error: { status: axiosError.response?.status, message: errMsg } }
  }
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({})
})
