import AccountDeletion from "./AccountDeletion"
import AccountPrivacy from "./AccountPrivacy"
import ConsentManagement from "./ConsentManagement"
import FeedbackAndSupport from "./FeedbackAndSupport"

export default {
  "consent-management": ConsentManagement,
  "account-deletion": AccountDeletion,
  feedback: FeedbackAndSupport,
  "account-privacy": AccountPrivacy
}
