import { Fragment } from "react"
import { Route, Routes } from "react-router-dom"

import publicRoutes from "./publicRoutes"
import { flexTimeRoutes, socialMediaRoutes } from "./privateRoutes"
import PublicRouteValidator from "./validator/PublicRouteValidator"
import ProtectedRouteValidator from "./validator/ProtectedRouteValidator"
import FlexTimeLayout from "../layouts/flex-time/FlexTimeLayout"
import SocialMediaLayout from "../layouts/social-media/SocialMediaLayout"
import { useSelector } from "react-redux"

const rolesBasedRoutes = role => {
  switch (role) {
    case "Social Media User":
      return (
        <Route element={<SocialMediaLayout />}>
          {socialMediaRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
        </Route>
      )
    case "Worker":
      return (
        <Route element={<FlexTimeLayout />}>
          {flexTimeRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={
                <ProtectedRouteValidator>
                  <route.component />
                </ProtectedRouteValidator>
              }
            />
          ))}
        </Route>
      )
  }
}

const AppRoutes = () => {
  const role = useSelector(state =>
    state?.auth?.role ? state?.auth?.role : ""
  )

  return (
    <Fragment>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            exact
            strict
            element={
              <PublicRouteValidator>
                <route.component />
              </PublicRouteValidator>
            }
          />
        ))}
        {rolesBasedRoutes(role)}
      </Routes>
    </Fragment>
  )
}

export default AppRoutes
