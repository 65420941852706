import { apiSlice } from "../apiSlice"

import {
  CREATE_SOCIAL_MEDIA_POST,
  ADD_USER_CONNECTIONS,
  DELETE_USER_CONNECTION,
  GET_SOCIAL_FEED,
  GET_USER_CONNECTIONS,
  GET_USER_SUGGESTIONS,
  TOGGLE_POST_LIKE,
  ADD_POST_COMMENT,
  GET_USER_POSTS
} from "../../constants/endpoints"
import { getUser } from "../auth"

export const socialMedaiApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSocialFeed: builder.query({
      query: () => ({
        url: GET_SOCIAL_FEED
      })
    }),
    getUserConnections: builder.query({
      query: searchTerm => ({
        url: GET_USER_CONNECTIONS.concat(`?search=${searchTerm}`)
      })
    }),
    getSuggestionlist: builder.query({
      query: () => ({
        url: GET_USER_SUGGESTIONS
      })
    }),
    deleteUserConnection: builder.mutation({
      query: id => ({
        url: DELETE_USER_CONNECTION.replace(":id", id),
        method: "DELETE"
      })
    }),
    addUserConnection: builder.mutation({
      query: body => ({
        url: ADD_USER_CONNECTIONS,
        method: "POST",
        body: body
      })
    }),
    createPost: builder.mutation({
      query: body => ({
        url: CREATE_SOCIAL_MEDIA_POST,
        method: "POST",
        body: body
      })
    }),
    togglePostLike: builder.mutation({
      query: id => ({
        url: TOGGLE_POST_LIKE.replace(":postId", id),
        method: "POST"
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const currentUser = getUser()
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getSocialFeed", undefined, draft => {
            const post = draft.find(post => post.post_id === id)
            if (post) {
              if (post.user_has_liked) {
                post.likes_count -= 1
                post.likes = post.likes.filter(
                  like => like.user_id !== currentUser.id
                )
              } else {
                post.likes_count += 1
                const newLike = {
                  user_id: currentUser.id,
                  username: currentUser.name,
                  name: currentUser.name,
                  profile_picture: null
                }
                post.likes.push(newLike)
              }
              post.user_has_liked = !post.user_has_liked
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    addCommentToPost: builder.mutation({
      query: ({ postId, content }) => ({
        url: ADD_POST_COMMENT.replace(":postId", postId),
        method: "POST",
        body: { content }
      }),
      async onQueryStarted({ postId, content }, { dispatch, queryFulfilled }) {
        const currentUser = getUser() // Assume you have the current user in state

        // Optimistically update the post's comments
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getSocialFeed", undefined, draft => {
            const post = draft.find(post => post.post_id === postId)
            if (post) {
              post.comments.push({
                comment_id: Date.now(), // Temporary ID
                author: {
                  user_id: currentUser.id,
                  username: null,
                  name: currentUser.name,
                  profile_picture: null
                },
                content: content,
                created_at: new Date().toISOString()
              })
              post.comments_count += 1
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getUserPosts: builder.query({
      query: () => ({
        url: GET_USER_POSTS
      })
    })
  }),
  overrideExisting: false
})

export const {
  useGetSocialFeedQuery,
  useGetUserConnectionsQuery,
  useGetSuggestionlistQuery,
  useDeleteUserConnectionMutation,
  useAddUserConnectionMutation,
  useCreatePostMutation,
  useTogglePostLikeMutation,
  useAddCommentToPostMutation,
  useGetUserPostsQuery
} = socialMedaiApiSlice
