import React from "react"
import { Button, Typography } from "antd"
import { useFormik } from "formik"

import CustomInput from "../../components/customInput/CustomInput"
import useRouterNavigation from "../../hooks/useNavigate"

import { useForgotPasswordMutation } from "../../api/apiSlices/authApiSlice"

import styles from "../login/auth.module.scss"
import { openNotification } from "../../util/openNotifications"
import { forgotPasswordValidationSchema } from "../../schemas/ForgotPasswordValidation"

const { Title, Text } = Typography

const ForgotPassword = () => {
  const [
    forgotPassword,
    { isLoading: isForgotPasswordLoading, error: forgotPasswordError }
  ] = useForgotPasswordMutation()

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: { email: "" },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async values => {
      await forgotPassword(values).unwrap()
      openNotification({
        type: "success",
        message: "Password reset email sent"
      })
      resetForm()
    }
  })

  return (
    <div className={styles.AuthContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.AuthWrapper} style={{ height: "458px" }}>
          <div className={`${styles.forgotContent}`}>
            <div className="text-center mb-32">
              <Title level={3} className="mb-2">
                Forgot Password
              </Title>
              <Text type="secondary">
                Enter your email to get password reset link.
              </Text>
            </div>
            <CustomInput
              value={values?.email}
              onChange={e => setValues({ email: e.target.value })}
              label="Email"
              placeholder="Enter Email"
              errorMessage={errors?.email}
            />
          </div>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            disabled={isForgotPasswordLoading}
            block
          >
            Send Link
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
