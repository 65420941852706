import React from "react"
import { Button, Checkbox, Typography } from "antd"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { useGoogleLogin } from "@react-oauth/google"
import { useFormik } from "formik"
import CustomInput from "../../components/customInput/CustomInput"
import {
  useFacebookSSOLoginMutation,
  useGoogleSSOLoginMutation,
  useLoginMutation
} from "../../api/apiSlices/authApiSlice"

import { loginValidationSchema } from "../../schemas/LoginValidationSchema"

import { FacebookIcon, GoogleIcon, AppleIcon } from "../../assets/rawSvgs/index"

import { Routes } from "../../constants/routes"

import styles from "./auth.module.scss"
import useRouterNavigation from "../../hooks/useNavigate"
import { setJWTToken, setRefreshToken, setUser } from "../../api/auth"
import { openNotification } from "../../util/openNotifications"

const { Text } = Typography

const Login = () => {
  const [login, { isLoading: isLoginLoading, error: loginError }] =
    useLoginMutation()

  const [
    facebookSSOLogin,
    { isLoading: isFacebookLoading, error: facebookError }
  ] = useFacebookSSOLoginMutation()
  const [googleSSOLogin, { isLoading: isGoogleLoading, error: googleError }] =
    useGoogleSSOLoginMutation()

  const { navigateTo } = useRouterNavigation()

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const data = await googleSSOLogin({
          access_token: tokenResponse.access_token
        }).unwrap()

        if (data?.tokens) {
          openNotification({
            type: "success",
            message: "Login Successful"
          })
          setJWTToken(data?.tokens?.access)
          setRefreshToken(data?.tokens?.refresh)
          setUser(data?.user)
          navigateTo(Routes.SETTINGS)
        }
      } catch (error) {
        console.error(error)
      }
    },
    onError: () => {
      console.log("Login Failed")
    }
  })

  const handleSuccess = async response => {
    try {
      console.log("Facebook response=", response)
      const apiResponse = await facebookSSOLogin(response).unwrap()
      console.log(apiResponse.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFailure = error => {
    console.log("Facebook Login Failed", error)
  }

  const loginForm = {
    email: "",
    password: ""
  }

  const { values, setValues, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: loginForm,
      validationSchema: loginValidationSchema,
      onSubmit: async values => {
        try {
          const data = await login(values).unwrap()
          if (data?.otp_required) {
            openNotification({
              type: "success",
              message: "OTP sent to your email"
            })
            setUser(data)
            navigateTo(`${Routes.OTP}`)
          } else if (data?.tokens) {
            openNotification({
              type: "success",
              message: "Login Successful"
            })
            setJWTToken(data?.tokens?.access)
            navigateTo(Routes.SETTINGS)
          }
        } catch (error) {
          console.log("error", error)
        }
      }
    })

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <h4 className={styles.loginTitle}>Log In</h4>
          <p className={styles.helperText}>
            Welcome Back! Please enter your credentials.
          </p>
        </div>
        <div className={styles.socialIcons}>
          <div className={styles.loginIconsWrap} onClick={() => googleLogin()}>
            <GoogleIcon />
          </div>

          <FacebookLogin
            appId="1749896512211777"
            autoLoad={false}
            fields="name,email,picture"
            callback={handleSuccess}
            onFailure={handleFailure}
            render={renderProps => (
              <div
                className={styles.loginIconsWrap}
                onClick={renderProps.onClick}
              >
                <FacebookIcon />
              </div>
            )}
          />

          <div className={styles.loginIconsWrap}>
            <AppleIcon />
          </div>
        </div>
        <div className={styles.orDivider}>
          <hr className={styles.line} />
          <p>OR</p>
          <hr className={styles.line} />
        </div>
        <form onSubmit={handleSubmit} className="w-100">
          <CustomInput
            label="Email"
            inputwd="w-100 mb-3"
            placeholder="Email"
            className="mb-3"
            value={values?.email}
            onChange={e => setValues({ ...values, email: e.target.value })}
            type="email"
            errorMessage={errors?.email}
          />
          <CustomInput
            label="Password"
            inputwd="w-100"
            type="password"
            placeholder="Password"
            value={values?.password}
            onChange={e => setValues({ ...values, password: e.target.value })}
            errorMessage={errors?.password}
          />
          <div className={styles.options}>
            <Checkbox className="text-xs-500">Remember me</Checkbox>
            <Text
              className={styles.forgot}
              onClick={() => navigateTo(Routes.FORGOT_PASSWORD)}
            >
              Forgot password?
            </Text>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            block
            disabled={isLoginLoading || isFacebookLoading || isGoogleLoading}
          >
            Log In
          </Button>
        </form>
        <p className={styles.termsText}>
          By signing in you are agreeing to our{" "}
          <span onClick={() => navigateTo(Routes.TERMS_AND_CONDITION)}>
            Terms & Conditions
          </span>
        </p>
      </div>
    </div>
  )
}

export default Login
