import ForgotPassword from "../pages/forgot-password"
import Login from "../pages/login"
import OtpScreen from "../pages/otp-screen"
import ResetPassword from "../pages/reset-password"
import TwoFactorAuthentication from "../pages/two-factor-authentication"
import Register from "../pages/register"
import PublicMessage from "../pages/public-message/PublicMessage"
import PrivacyPolicy from "../pages/privacy-policy"
import TermsAndConditions from "../pages/terms-and-conditions"
import LandingPage from "../pages/landing-page"

import { Routes } from "../constants/routes"
import AllJobs from "../pages/jobs/AllJobs"

const routeObjects = [
  {
    path: Routes.LANDING,
    component: LandingPage
  },
  {
    path: Routes.LOGIN,
    component: Login
  },
  {
    path: Routes.FORGOT_PASSWORD,
    component: ForgotPassword
  },
  {
    path: Routes.OTP,
    component: OtpScreen
  },
  {
    path: Routes.TWOFA,
    component: TwoFactorAuthentication
  },
  {
    path: Routes.RESET_PASSWORD,
    component: ResetPassword
  },
  {
    path: Routes.REGISTER,
    component: Register
  },
  {
    path: Routes.PUBLIC_MESSAGE,
    component: PublicMessage
  },
  {
    path: Routes.PRIVACY_POLICY,
    component: PrivacyPolicy
  },
  {
    path: Routes.JOBS,
    component: AllJobs
  },
  { path: Routes.TERMS_AND_CONDITION, component: TermsAndConditions }
]

export default routeObjects
