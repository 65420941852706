import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Modal, Spin, Switch, Typography } from "antd"
import {
  useFetchVisibilitySettingsQuery,
  useUpdateVisibilitySettingsMutation
} from "../../../../api/apiSlices/settingsApiSlice"
import "./styles.scss"
import { useEffect, useState } from "react"
import { openNotification } from "../../../../util/openNotifications"

const { Text } = Typography

export default function VisibilitySettingsModal({ onClose }) {
  const [visibilitySettings, setVisibilitySettings] = useState()
  const { data, isLoading, refetch } = useFetchVisibilitySettingsQuery()
  const [updateVisibilitySettings] = useUpdateVisibilitySettingsMutation()

  useEffect(() => {
    if (!isLoading && data) {
      setVisibilitySettings(data)
    }
  }, [data])

  const onChangeStatus = (key, value) => {
    setVisibilitySettings({ ...visibilitySettings, [key]: value })
  }

  const onSave = () => {
    updateVisibilitySettings({
      show_profile_picture: visibilitySettings.show_profile_picture,
      show_user_name: visibilitySettings.show_user_name,
      show_age: visibilitySettings.show_age,
      show_gender: visibilitySettings.show_gender
    }).then(() => {
      refetch()
      openNotification({
        type: "success",
        message: "Visibility Settings updated"
      })
      onClose()
    })
  }

  return (
    <Modal
      title="Profile Visibility Settings"
      icon={<ExclamationCircleOutlined />}
      centered
      open
      cancelText="Cancel"
      okText="Save"
      onCancel={onClose}
      onOk={onSave}
    >
      <div>
        <Spin spinning={isLoading}>
          <div className="visibilityCard">
            <div className="settingsInfo">
              <Avatar icon={<UserOutlined />} />
              <Text className="title">Profile Picture</Text>
            </div>
            <div>
              <Switch
                value={visibilitySettings?.show_profile_picture}
                onChange={value =>
                  onChangeStatus("show_profile_picture", value)
                }
              />
            </div>
          </div>
          <div className="visibilityCard">
            <div className="settingsInfo">
              <Text className="title">User Name</Text>
              <Text className="description">{}</Text>
            </div>
            <div>
              <Switch
                value={visibilitySettings?.show_user_name}
                onChange={value => onChangeStatus("show_user_name", value)}
              />
            </div>
          </div>
          <div className="visibilityCard">
            <div className="settingsInfo">
              <Text className="title">Age</Text>
              <Text className="description">{}</Text>
            </div>
            <div>
              <Switch
                value={visibilitySettings?.show_age}
                onChange={value => onChangeStatus("show_age", value)}
              />
            </div>
          </div>
          <div className="visibilityCard">
            <div className="settingsInfo">
              <Text className="title">Gender</Text>
              <Text className="description">{}</Text>
            </div>
            <div>
              <Switch
                value={visibilitySettings?.show_gender}
                onChange={value => onChangeStatus("show_gender", value)}
              />
            </div>
          </div>
          <div className="visibilityCard">
            <div className="settingsInfo">
              <Text className="title">Friends List</Text>
              <Text className="description">{}</Text>
            </div>
            <div>
              <Switch
                value={visibilitySettings?.show_friends_list}
                onChange={value => onChangeStatus("show_friends_list", value)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  )
}
