import React, { useState } from "react"
import {
  Avatar,
  Space,
  Typography,
  Flex,
  Button,
  Image,
  Input,
  Carousel
} from "antd"
import moment from "moment"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import {
  HeartOutlined,
  CommentOutlined,
  SmileOutlined,
  UpOutlined,
  HeartFilled
} from "@ant-design/icons"

import { openNotification } from "../../../../util/openNotifications"

import "./postCard.scss"

const { Text, Title } = Typography

const defaultFn = () => ""

const defaultAsyncFn = async () => ""

function PostCard({
  index,
  content = "",
  likes_count = 0,
  attachments = [],
  author = null,
  comments = [],
  comments_count = 0,
  comments_enabled = true,
  post_id = null,
  created_at = null,
  addCommentToPostError = {},
  user_has_liked = false,
  likes_enabled = true,
  likes = [],
  isAddCommentToPostLoading = false,
  addCommentToPost = defaultAsyncFn,
  togglePostLike = defaultAsyncFn
}) {
  const [showCommentsSection, setShowCommentsSection] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [comment, setComment] = useState("")
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const toggleLike = async id => {
    try {
      await togglePostLike(id).unwrap()
    } catch (error) {
      openNotification({
        type: "error",
        message: error?.message
      })
    }
  }

  const handleAddComment = async (id, comment) => {
    try {
      const payload = {
        postId: id,
        content: comment
      }
      await addCommentToPost(payload).unwrap()
      setComment("")
    } catch (error) {
      setComment("")
      openNotification({
        type: "error",
        message: error?.message
      })
    }
  }

  const addEmoji = emoji => {
    console.log("emoji", emoji)
    setComment(comment + emoji.native)
  }

  return (
    <div className="post-card-wrapper" index={index}>
      <Flex align="center" gap="8px" className="post-card-header">
        <Avatar size={32} />
        <Title level={5}>{author?.name ?? "Anonymous"}</Title>
        <Space>
          <span></span>
          <Text>{moment(created_at).fromNow()}</Text>
        </Space>
      </Flex>
      <div className="post-card-body">
        <Text>{content}</Text>
        <Carousel arrows infinite={false}>
          {attachments?.map(attachment => {
            return (
              <>
                {attachment?.type === "image" ? (
                  <Image
                    className="post-image-wrapper"
                    src={attachment?.presigned_url?.url}
                  />
                ) : (
                  <video
                    controls
                    src={attachment?.presigned_url?.url}
                    width="100%"
                  >
                    <source
                      src={attachment?.presigned_url?.url}
                      type="video/mp4"
                    />
                  </video>
                )}
              </>
            )
          })}
        </Carousel>
        <Flex
          justify="space-between"
          align="center"
          className="post-action-details"
        >
          {likes_enabled && (
            <Flex gap="small" align="center">
              <Button
                shape="circle"
                icon={<HeartOutlined />}
                className="like-action-btn"
              />
              {likes?.length > 1 ? (
                <Text>
                  {likes[likes?.length - 1]?.name} and {likes_count - 1} others
                </Text>
              ) : (
                <Text>{likes[0]?.name}</Text>
              )}
            </Flex>
          )}
          <Text>{comments_count} comments</Text>
        </Flex>
        <Flex gap="small" className="post-card-actions">
          <Button
            type="text"
            iconPosition="start"
            icon={
              user_has_liked ? (
                <HeartFilled color="#CF1322" />
              ) : (
                <HeartOutlined color="black" />
              )
            }
            onClick={() => toggleLike(post_id)}
          >
            {user_has_liked ? "liked" : "Like"}
          </Button>
          {comments_enabled && (
            <Button
              type="text"
              iconPosition="start"
              icon={<CommentOutlined />}
              onClick={() => setShowCommentsSection(!showCommentsSection)}
            >
              Comment
            </Button>
          )}
        </Flex>
        {showCommentsSection && (
          <div className="post-comments-wrapper">
            <Flex gap="small" className="write-comment-wrapper">
              <Avatar size={48} />
              <Input
                size="large"
                placeholder="Add a comment…"
                value={comment}
                onChange={e => setComment(e.target.value)}
                suffix={
                  <Button
                    primary
                    disabled={isAddCommentToPostLoading}
                    size="small"
                    type="link"
                    onClick={() => handleAddComment(post_id, comment)}
                  >
                    Send
                  </Button>
                }
                prefix={
                  <div style={{ position: "relative" }}>
                    <SmileOutlined
                      color="#00000073"
                      size={24}
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    />
                    {showEmojiPicker && (
                      <div
                        style={{
                          position: "absolute",
                          top: "40px",
                          left: "0px",
                          zIndex: 10
                        }}
                      >
                        <Picker data={data} onEmojiSelect={addEmoji} />
                      </div>
                    )}
                  </div>
                }
              />
            </Flex>
            {comments?.length > 0 && (
              <Button
                type="link"
                primary
                onClick={() => setShowComments(!showComments)}
                className="show-comments-list-btn"
              >
                View all {comments_count} comments
                <UpOutlined />
              </Button>
            )}
            {showComments && (
              <ul className="inner-comments-wrapper">
                {comments?.map(comment => {
                  return (
                    <Flex gap="small" className="single-comment">
                      <Avatar size={32} />
                      <Flex gap="small" vertical className="comment-message">
                        <Title level={5}>
                          {comment?.author?.name ?? "Anonymous"}
                        </Title>
                        <Text>{comment?.content}</Text>
                      </Flex>
                    </Flex>
                  )
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PostCard
