import { Col, Flex, Menu, Row } from "antd"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import tabComponents from "./tabs/tabComponents"
import CustomInput from "../../components/customInput/CustomInput"
import { SearchOutlined } from '@ant-design/icons';
import LandingFooter from "../../components/footer/LandingFooter"


const items = [
  {
    key: "account-privacy",
    label: "Account Privacy"
  },
  {
    key: "consent-management",
    label: "Consent Management"
  },
  {
    key: "account-deletion",
    label: "Account Deletion"
  },
  {
    key: "feedback",
    label: "Feedback and Support"
  }
]

const Settings = () => {
  const [currentTabIndex, setcurrentTabIndex] = useState("account-privacy")

  const currentTabLabel = items.find(item => item.key === currentTabIndex).label
  const CurrentTabComponent = tabComponents[currentTabIndex]
  return (
    <Row gutter={[48, 48]}>
      <Col xl={6} xxl={4}>
        <Flex vertical gap={32}>
          <div className="">
            <Title level={2} className="mb-3" aria-label="Settings">
              Settings
            </Title>
            <CustomInput placeholder="Search" suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />} />
          </div>
          <Menu
            style={{ width: "100%" }}
            defaultSelectedKeys={currentTabIndex}
            defaultOpenKeys={currentTabIndex}
            mode="vertical"
            items={items}
            onSelect={event => setcurrentTabIndex(event.key)}
          />
        </Flex>
      </Col>
      <Col xl={18} xxl={20}>
        <Title level={4} aria-label={currentTabLabel} className="mb-4">
          {currentTabLabel}
        </Title>
        <CurrentTabComponent />
      </Col>
    </Row>
  )
}

export default Settings
