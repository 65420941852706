import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  UpOutlined,
  UserAddOutlined
} from "@ant-design/icons"
import {
  Avatar,
  Button,
  Flex,
  Image,
  Modal,
  Spin,
  Switch,
  Typography,
  Upload
} from "antd"
import {
  useAddMediaMutation,
  useDeleteMediaMutation
} from "../../../../api/apiSlices/settingsApiSlice"
import { useState } from "react"
import "./styles.scss"
import TextArea from "antd/es/input/TextArea"
import { getBase64 } from "../../../../util/GetBase64"
import { useCreatePostMutation } from "../../../../api/apiSlices/socialMediaApiSlice"
import { openNotification } from "../../../../util/openNotifications"
import { getUser } from "../../../../api/auth"

const { Text } = Typography
// Maximum file size in bytes (10 MB)
const MAX_FILE_SIZE = 10 * 1024 * 1024

export default function CreatePost({ onClose }) {
  const [text, setText] = useState("")
  const [showPostSettings, setShowPostSettings] = useState(false)
  const [postSettings, setPostSettings] = useState({
    comments_enabled: true,
    likes_enabled: true
  })
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewVideo, setPreviewVideo] = useState()
  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false)
  const [fileList, setFileList] = useState([])
  const [attachments, setAttachments] = useState([])
  const [addMedia] = useAddMediaMutation()
  const [createNewPost, { isLoading: isAddingPost }] = useCreatePostMutation()
  const currentUser = getUser()

  const [deleteMedia] = useDeleteMediaMutation()

  const onChangePostSettings = (key, value) => {
    setPostSettings({ ...postSettings, [key]: value })
  }

  const onConfirmCreatePost = () => {
    if (text.length === 0 && attachments.length === 0) {
      openNotification({
        type: "error",
        message: "Post can't be empty"
      })
      return
    }
    const data = {
      content: text,
      attachments: attachments,
      ...postSettings
    }
    createNewPost(data)
      .then(() => {
        openNotification({
          type: "success",
          message: "Post created successfully"
        })
        onClose()
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: "Failed to create post please try again"
        })
      })
  }
  const togglePostSettings = () => setShowPostSettings(!showPostSettings)

  const handlePreview = async file => {
    if (file.response.type === "video") {
      setPreviewVideo({ url: file.response.url, type: file.type })
      setIsVideoPreviewOpen(true)
      return
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handleUploadFile = async ({ file, onSuccess, onError }) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData)
      setAttachments(prev => [...prev, data?.data])
      onSuccess(data.data, file)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid
            ? { ...item, thumbUrl: data?.data?.url, status: "done" }
            : item
        )
      )
    } catch (error) {
      onError(error)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid ? { ...item, status: "error" } : item
        )
      )
    }
  }

  const handleRemoveFile = async file => {
    setAttachments(prevList =>
      prevList.filter(item => item.object_key !== file.response.object_key)
    )
    await deleteMedia({ object_key: file.response.object_key })
  }

  const beforeUpload = file => {
    const isImage = file.type.startsWith("image/")
    const isVideo = file.type.startsWith("video/")

    if (!isImage && !isVideo) {
      openNotification({
        type: "error",
        message: "You can only upload image or video files!"
      })
      return Upload.LIST_IGNORE
    }

    if (file.size > MAX_FILE_SIZE) {
      openNotification({
        type: "error",
        message: "Upload exceeds the maximum supported size of 10MB"
      })
      return Upload.LIST_IGNORE
    }

    return true
  }

  const onCancelCreatePost = () => {
    const promises = attachments.map(attachment => {
      return deleteMedia({ object_key: attachment.object_key })
    })
    onClose(promises)
  }

  return (
    <Modal
      title="Create Post"
      icon={<ExclamationCircleOutlined />}
      centered
      open
      className="CustomModalWrapper"
      cancelText="Cancel"
      okText="Post"
      onCancel={onCancelCreatePost}
      onOk={onConfirmCreatePost}
      confirmLoading={isAddingPost}
      okButtonProps={{ disabled: isAddingPost }}
      width={600}
    >
      <div className="postModal">
        <Spin spinning={false}>
          <div className="info">
            <Avatar size="small" icon={<UserAddOutlined />} />
            <p className="title">{currentUser?.name}</p>
          </div>
          <div className="textArea">
            <TextArea
              value={text}
              onChange={e => setText(e.target.value)}
              placeholder="What’s on your mind?"
              autoSize={{
                minRows: 1,
                maxRows: 7
              }}
              className="customInput"
            />
          </div>
          <div className="fileUpload">
            <Upload
              listType="picture-card"
              accept="image/*,video/*"
              customRequest={handleUploadFile}
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleFileChange}
              onRemove={handleRemoveFile}
              beforeUpload={beforeUpload}
            >
              <Flex vertical gap="small" align="center">
                <PlusOutlined />
                Attach Media
              </Flex>
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none"
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: visible => setPreviewOpen(visible),
                  afterOpenChange: visible => !visible && setPreviewImage("")
                }}
                src={previewImage}
              />
            )}
            {isVideoPreviewOpen && (
              <Modal
                open={isVideoPreviewOpen}
                footer={null}
                onCancel={() => setIsVideoPreviewOpen(false)}
                width={800}
                centered
                wrapperStyle={{
                  display: "none"
                }}
              >
                <video controls width="100%" className="videoPreview">
                  <source src={previewVideo.url} type={previewVideo.type} />
                  Your browser does not support the video tag.
                </video>
              </Modal>
            )}
          </div>
          <div className="settings">
            <Button
              icon={showPostSettings ? <UpOutlined /> : <DownOutlined />}
              iconPosition="end"
              onClick={togglePostSettings}
            >
              Post Settings
            </Button>
            {showPostSettings && (
              <div className="postSettingContainer">
                <div className="postSettings">
                  <Text>Hide number of likes on this post</Text>
                  <Switch
                    value={postSettings.show_likes_count}
                    onChange={status =>
                      onChangePostSettings("likes_enabled", status)
                    }
                  />
                </div>
                <div className="postSettings">
                  <Text>Turn off commenting</Text>
                  <Switch
                    value={postSettings.comments_enabled}
                    onChange={status =>
                      onChangePostSettings("comments_enabled", status)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </Modal>
  )
}
