import { Layout } from "antd"
import React from "react"
import SideNav from "../../components/sider/SideNav"
import { Outlet } from "react-router-dom"

const { Content } = Layout

export default function SocialMediaLayout() {
  return (
    <Layout>
      <SideNav />
      <Content className="SiderContentContainer">
        <Outlet />
      </Content>
    </Layout>
  )
}
