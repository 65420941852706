import { apiSlice } from "../apiSlice"

import {
  EDIT_WORKER_PROFILE,
  GET_COUNTRIES,
  GET_WORKER_PROFILE
} from "../../constants/endpoints"

export const flexTimeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getWorkerProfile: builder.query({
      query: () => ({
        url: GET_WORKER_PROFILE
      })
    }),
    getCountries: builder.query({
      query: () => ({
        url: GET_COUNTRIES
      })
    }),
    editWorkerProfile: builder.mutation({
      query: body => ({
        url: EDIT_WORKER_PROFILE,
        method: "POST",
        body: body
      })
    })
  }),
  overrideExisting: false
})

export const {
  useGetWorkerProfileQuery,
  useGetCountriesQuery,
  useEditWorkerProfileMutation
} = flexTimeApiSlice
