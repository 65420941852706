import { Button, Card, Flex, Typography } from "antd"
import Arrowup from "../../../assets/images/arrow.svg"
import { ReactComponent as EmployerIcon } from "../../../assets/common/employer.svg"
import { ReactComponent as WorkerIcon } from "../../../assets/common/worker.svg"
import "./styles.scss"

const { Title, Paragraph } = Typography

const roles = [
  {
    name: "social_media_user",
    title: "Social Media",
    theme: "pink",
    description:
      "Engage with a vibrant community and connect across platforms. Share, discover, and view posts from various networks—all in one place."
  },
  {
    name: "flex-time",
    title: "Flextime Force",
    theme: "purple",
    description:
      "Engage with a vibrant community and connect across platforms. Share, discover, and view posts from various networks—all in one place.",
    subRoles: [
      {
        name: "worker",
        title: "Join as worker",
        theme: "pink",
        description: "I am a freelancer looking for flexible job opportunities",
        icon: WorkerIcon
      },
      {
        name: "employer",
        title: "Join as an Employer",
        theme: "pink",
        description:
          "I am a client looking for talented individuals to work on my project.",
        icon: EmployerIcon
      }
    ]
  }
]

export default function RoleSelection({ mainRole = null, onChangeMainRole }) {
  return (
    <div className="roleSelection">
      {!mainRole && (
        <>
          <Title>Choose which service you are signing up to</Title>
          <Flex gap="16px" className="mt-5">
            {roles.map(role => (
              <Card
                className={`card-service ${role.theme}-services`}
                onClick={() => onChangeMainRole(role.name)}
              >
                <Title className="card-headings" level={4}>
                  {role.title}
                </Title>
                <Flex gap="small">
                  <div className="card-text-box">
                    <Paragraph className="paragraph-text">
                      {role.description}
                    </Paragraph>
                  </div>
                  <Button className="rounded-btn" type="primary" shape="circle">
                    <img src={Arrowup} className="img-fluid" alt="img" />
                  </Button>
                </Flex>
              </Card>
            ))}
          </Flex>
        </>
      )}
      {mainRole === "flex-time" && (
        <Flex gap="16px">
          {roles[1].subRoles.map(({ title, name, description, icon: Icon }) => (
            <Card
              className={`card-service flex-time-services`}
              onClick={() => onChangeMainRole(name)}
            >
              <Icon />
              <Title className="card-headings" level={4}>
                {title}
              </Title>
              <div className="card-text-box">
                <Paragraph className="paragraph-text">{description}</Paragraph>
              </div>
            </Card>
          ))}
        </Flex>
      )}
    </div>
  )
}
