import React from "react"
import { Typography } from "antd"
import SuggestionsListItem from "./suggestionsListItem"

import {
  useAddUserConnectionMutation,
  useGetSuggestionlistQuery
} from "../../../../api/apiSlices/socialMediaApiSlice"

import "./suggestionsList.scss"

const { Text, Title } = Typography

function SuggestionsList() {
  const {
    data: suggestions,
    isLoading: isSuggestionsLoading,
    refetch: refetchSuggestions
  } = useGetSuggestionlistQuery()

  const [
    addUserConnection,
    { isLoading: isAddUserConnectionLoading, error: addUserConnectionError }
  ] = useAddUserConnectionMutation()

  console.log("suggestions", suggestions)

  const onAddFriend = async id => {
    const payload = {
      connected_user: id
    }
    await addUserConnection(payload)
    refetchSuggestions()
  }

  return (
    <div className="Suggestions-list-wrapper">
      <Title level={4} className="suggestions-list-title">
        Suggestions for you
      </Title>
      {suggestions?.length > 0 ? (
        suggestions?.map(suggestion => {
          return (
            <SuggestionsListItem
              name={suggestion?.name}
              username={suggestion?.username}
              user_id={suggestion?.user_id}
              onAddFriend={onAddFriend}
            />
          )
        })
      ) : (
        <Text>No suggestions Found</Text>
      )}
    </div>
  )
}

export default SuggestionsList
