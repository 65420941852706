import React, { useRef, useState } from "react"
import { Typography, Button, Input } from "antd"

import styles from "../login/auth.module.scss"
import {
  useResendOtpMutation,
  useVerifyOtpMutation
} from "../../api/apiSlices/authApiSlice"
import { getUser, setJWTToken, setRefreshToken, setUser } from "../../api/auth"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { openNotification } from "../../util/openNotifications"
import { setUserRole } from "../../redux/slices/auth"
import { useDispatch } from "react-redux"

const { Title, Text } = Typography
const OtpScreen = () => {
  const [otp, setOtp] = useState(["", "", "", ""])

  const inputRefs = useRef([])

  const [verifyOtp, { isLoading: isVerifyOtpLoading }] = useVerifyOtpMutation()

  const [resendOtp, { isLoading: isResendOtpLoading }] = useResendOtpMutation()

  const { navigateTo } = useRouterNavigation()

  const dispatch = useDispatch()

  const email = getUser()?.email

  const handleInputChange = (e, index) => {
    const value = e.target.value

    if (value.length > 1) {
      return
    }

    let newOtp = [...otp]
    newOtp[index] = value

    setOtp(newOtp)

    if (index < inputRefs.current.length - 1 && value !== "") {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus()
    }
  }

  const handlePaste = e => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData("text/plain")
    const newOtp = [...otp]

    for (let i = 0; i < 4; i++) {
      newOtp[i] = pastedData.replace(/[^a-zA-Z0-9]/g, "")[i]
    }

    setOtp(newOtp)
  }

  const handleSubmitOtp = async () => {
    const mergedOtp = Number(otp.join(""))
    const data = await verifyOtp({
      otp: mergedOtp,
      email: email
    }).unwrap()
    if (data?.tokens) {
      openNotification({
        type: "success",
        message: "OTP verification successful. You are now logged in."
      })
      setJWTToken(data?.tokens?.access)
      setRefreshToken(data?.tokens?.refresh)
      setUser(data?.user)
      dispatch(setUserRole({ role: data?.user?.role }))
      if (data?.user?.role === "Social Media User") {
        navigateTo(Routes.HOME)
      } else if (data?.user?.role === "Worker") {
        navigateTo(Routes.USER_EXPERIENCE)
      }
    }
  }

  const onResendOTP = () => {
    if (isResendOtpLoading || isVerifyOtpLoading) {
      return
    }
    resendOtp({ email }).then(() => {
      openNotification({
        type: "success",
        message: "OTP sent to your email"
      })
    })
  }

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.AuthWrapper} style={{ height: "458px" }}>
        <div className={styles.forgotContent}>
          <div className="text-center mb-32">
            <Title level={3} className="mb-6">
              Enter OTP
            </Title>
            <Text type="secondary">We have sent an OTP to you number</Text>
          </div>
          <div className={styles.otpCodeWrap}>
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={ref => (inputRefs.current[index] = ref)}
                type="text"
                value={digit}
                maxLength={1}
                className={styles.otpCodeItem}
                onChange={e => handleInputChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                onPaste={e => handlePaste(e, index)}
                inputMode="text"
              />
            ))}
          </div>
          <Text className="text-center" type="secondary">
            Didn’t get a code?{" "}
            <Text
              type="secondary"
              underline
              onClick={onResendOTP}
              style={{
                cursor:
                  !isResendOtpLoading && !isVerifyOtpLoading ? "pointer" : ""
              }}
              disabled={isResendOtpLoading || isVerifyOtpLoading}
            >
              Click to resend
            </Text>
            .
          </Text>
        </div>
        <Button
          type="primary"
          size="large"
          block
          onClick={handleSubmitOtp}
          disabled={!otp.every(digit => digit !== "") || isVerifyOtpLoading}
        >
          Verify
        </Button>
      </div>
    </div>
  )
}

export default OtpScreen
