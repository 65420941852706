import React, { useState } from "react"
import { Avatar, Flex, Typography } from "antd"

import { BriefcaseIcon } from "../../../assets/rawSvgs"

const { Text, Paragraph } = Typography

const WorkExperienceJobDescription = ({
  company = "",
  jobTitle = "",
  jobDescription = ""
}) => {
  const [ellipsis, setEllipsis] = useState(true)
  return (
    <>
      <Flex gap={12} className="mt-12">
        <div>
          <Avatar
            size={36}
            icon={<BriefcaseIcon />}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.04)"
            }}
          />
        </div>
        <Flex vertical align="start">
          <Text className="TertiaryColor font-weight-500">{jobTitle}</Text>
          <Text className="font-weight-500 mb-2s">{company}</Text>
          <Paragraph
            className="mb-12"
            ellipsis={
              ellipsis
                ? {
                    rows: 2,
                    expandable: true,
                    symbol: "see more"
                  }
                : false
            }
          >
            {jobDescription}
          </Paragraph>
        </Flex>
      </Flex>
    </>
  )
}

export default WorkExperienceJobDescription
