import { useEffect } from "react"
import { Layout } from "antd"
import { useLocation } from "react-router-dom"

import useRouterNavigation from "../../hooks/useNavigate"

import { getUser, userHasValidToken } from "../../api/auth"
import LoginHeader from "../../components/loginHeader/LoginHeader"

import { Routes } from "../../constants/routes"

const { Content } = Layout

export default function PublicRouteValidator({ children, page }) {
  const location = useLocation()

  const { navigateTo } = useRouterNavigation()

  useEffect(() => {
    if (userHasValidToken()) {
      navigateTo(location.state?.from || Routes.SETTINGS, { replace: true })
    }
    // if (!getUser()?.email) {
    //   navigateTo(Routes.LOGIN)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <LoginHeader />
      <Content>{children}</Content>
    </Layout>
  )
}
