import React from "react"
import { Typography } from "antd"

import { useGetTermsAndConditionsQuery } from "../../api/apiSlices/settingsApiSlice"

import styles from "../privacy-policy/privacyStyles.module.scss"

const { Text, Title, Paragraph } = Typography

const TermsAndConditions = () => {
  const { data, refetch, isLoading } = useGetTermsAndConditionsQuery()

  return (
    <div className={styles.PrivacyMainContainer}>
      <div className={styles.PrivacyHeader}>
        <Text
          className="primaryColor font-weight-600 d-block mb-12"
          style={{ fontSize: "16px" }}
        >
          Current as of 20 Jan 2022
        </Text>
        <Title className="mb-4 font-weight-600" style={{ fontSize: "48px" }}>
          Terms And Conditions
        </Title>
        <Paragraph className="text-20-400 mb-0" type="secondary">
          Your privacy is important to us at Untitled. We respect your privacy
          regarding any information we may collect from you across our website.
        </Paragraph>
      </div>
      <div className={styles.PrivacyContainer}>
        {/* Static Data */}
        {/* <Paragraph className="mb-5 text-18-400" type="secondary">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id. <br />
          <br /> Eget quis mi enim, leo lacinia pharetra, semper. Eget in
          volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames
          arcu quis fusce augue enim. Quis at habitant diam at. Suscipit
          tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
          molestie aliquet sodales id est ac volutpat.{" "}
        </Paragraph>
        <Title level={2} className="mb-4 font-weight-600">
          What information do we collect?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
          odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis
          mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
          <br />
          <br />
          Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
          commodo consectetur convallis risus. Sed condimentum enim dignissim
          adipiscing faucibus consequat, urna. Viverra purus et erat auctor
          aliquam. Risus, volutpat vulputate posuere purus sit congue convallis
          aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque
          ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget
          nunc lectus in tellus, pharetra, porttitor.
          <br />
          <br />
          Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
          id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
          molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
          velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh
          orci.
        </Paragraph>
        <Title level={2} className="mb-4 font-weight-600">
          How do we use your information?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
          odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis
          mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
          <br />
          <br />
          Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
          commodo consectetur convallis risus. Sed condimentum enim dignissim
          adipiscing faucibus consequat, urna. Viverra purus et erat auctor
          aliquam. Risus, volutpat vulputate posuere purus sit congue convallis
          aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque
          ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget
          nunc lectus in tellus, pharetra, porttitor.
          <br />
          <br />
          Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
          id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
          molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
          velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh
          orci.
        </Paragraph>
        <Title level={3} className="mb-4 font-weight-600">
          Do we use cookies and other tracking technologies?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
        </Paragraph>
        <Title level={3} className="mb-4 font-weight-600">
          How long do we keep your information?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
        </Paragraph>
        <Title level={3} className="mb-4 font-weight-600">
          How do we keep your information safe?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
        </Paragraph>
        <Title level={2} className="mb-4 font-weight-600">
          How What are your privacy rights?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
        </Paragraph>
        <Title level={3} className="mb-4 font-weight-600">
          How can you contact us about this policy?
        </Title>
        <Paragraph className="mb-5 text-18-400" type="secondary">
          Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas
          sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor
          rutrum lacus malesuada massa ornare et. Vulputate consectetur ac
          ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim
          massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac
          elementum gravida cursus dis
          <br />
          <br />
          1. Lectus id duis vitae porttitor enim{" "}
          <Text underline className="text-18-400" type="secondary">
            gravida morbi
          </Text>
          .<br />
          2. Eu turpis{" "}
          <Text underline className="text-18-400" type="secondary">
            posuere semper feugiat{" "}
          </Text>
          volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.
          <br />
          3. Suspendisse maecenas ac{" "}
          <Text className="text-18-400" underline type="secondary">
            {" "}
            donec scelerisque{" "}
          </Text>
          diam sed est duis purus.
        </Paragraph> */}
        {/* API data  */}
        <Paragraph className="mb-5 text-18-400" type="secondary">
          {data?.content}
        </Paragraph>
      </div>
    </div>
  )
}

export default TermsAndConditions
