import React, { useState } from "react"
import { Breadcrumb, Flex, Layout, Avatar, Dropdown, Typography } from "antd"
import appLogo from "../../assets/common/logo.png"
import {
  BubbleChatIcon,
  FilledBubbleChatIcon,
  FilledHomeIcon,
  FilledNewPostIcon,
  FilledNotificationIcon,
  FilledSearchIcon,
  HomeIcon,
  MoreIcon,
  NewPostIcon,
  NotificationIcon,
  SearchIcon
} from "../../assets/rawSvgs"
import { UserOutlined } from "@ant-design/icons"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { Link } from "react-router-dom"
import { removeJWTToken, removeRefreshToken, removeUser } from "../../api/auth"
import { setIsOpenCreatePost } from "../../redux/slices/socialMedia"
import { useDispatch } from "react-redux"
import CreatePost from "../../pages/social-media/components/create-post/CreatePost"
import { useSelector } from "react-redux"

const { Text } = Typography

const { Sider } = Layout

const SideNav = () => {
  const [selectedIcon, setSelectedIcon] = useState("");
  const { navigateTo } = useRouterNavigation()
  const isOpenCreatePost = useSelector(
    state => state.socialMedia.isOpenCreatePost
  )
  let dispatch = useDispatch()

  const handleLogOut = () => {
    removeJWTToken()
    removeRefreshToken()
    removeUser()
    navigateTo(Routes.LOGIN)
  }

  const items = [
    {
      label: <Text onClick={handleLogOut}>Logout</Text>,
      key: "0"
    },
    {
      label: <Link to={Routes.SETTINGS}>Settings</Link>,
      key: "1"
    }
  ]

  const onCloseCreatePost = (promises = []) => {
    dispatch(setIsOpenCreatePost(false))
    if (promises.length > 0) {
      Promise.all(promises)
    }
  }

  const handleIconClick = (iconName, route) => {
    setSelectedIcon(iconName); 
    navigateTo(route); 
  };

  return (
    <Sider className="SiderWrapper" width={81}>
      <Flex vertical gap={48} align="center">
        <div className="AppLogoWrap">
          <img
            src={appLogo}
            alt="app logo"
            onClick={() => navigateTo(Routes.WORKER_PROFILE)}
          />
        </div>
        <Flex vertical gap="large">
          <div
            className="cursor-pointer"
            onClick={() => handleIconClick("home", Routes.HOME)}
          >
            {selectedIcon === "home" ? <FilledHomeIcon /> : <HomeIcon />}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleIconClick("search", Routes.SEARCH)}
          >
            {selectedIcon === "search" ? <FilledSearchIcon /> : <SearchIcon />}
          </div>
          <div
            onClick={() => handleIconClick("bubbleChat", Routes.HOME)}
            className="cursor-pointer"
          >
            {selectedIcon === "bubbleChat" ? <FilledBubbleChatIcon /> : <BubbleChatIcon />}
          </div>
          <div
            onClick={() => dispatch(setIsOpenCreatePost(true))}
            // onClick={() => handleIconClick("newPost", null)}
            className="cursor-pointer"
          >
            {selectedIcon === "newPost" ? <FilledNewPostIcon /> : <NewPostIcon />}
          </div>
          <div
            onClick={() => handleIconClick("notification", Routes.HOME)}
            className="cursor-pointer"
          >
            {selectedIcon === "notification" ? <FilledNotificationIcon /> : <NotificationIcon />}
          </div>
          <Avatar
            onClick={() => handleIconClick("profile", Routes.PROFILE)}
            size="small"
            icon={<UserOutlined />}
            className="cursor-pointer"
            style={{
              border: selectedIcon === "profile" ? "1px solid #3DC1DC" : "none",
              boxShadow: selectedIcon === "profile" ? "0px 0px 0px 2px rgba(24, 144, 255, 0.20)" : "none",
            }}
          />
        </Flex>
      </Flex>
      <div>
        <Dropdown
          menu={{
            items
          }}
          trigger={["click"]}
          overlayStyle={{ width: "165px" }}
        >
          <a onClick={e => e.preventDefault()}>
            <MoreIcon />
          </a>
        </Dropdown>
        {isOpenCreatePost && <CreatePost onClose={onCloseCreatePost} />}
      </div>
    </Sider>
  )
}

export default SideNav
