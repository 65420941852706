const AUTH = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  OTP: "/otp",
  TWOFA: "/two-factor-authentication",
  RESET_PASSWORD: "/reset-password/:id/:token",
  PUBLIC_MESSAGE: "/info",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-condition"
}

const APP_ROUTES = {
  SETTINGS: "/settings",
  PROFILE: "/profile",
  HOME: "/social-media-feed",
  WORKER_PROFILE: "/worker-profile",
  USER_EXPERIENCE: "/user-experience",
  JOBS: "/jobs",
  LANDING: "/"
}

export const Routes = {
  ...AUTH,
  ...APP_ROUTES
}
