import React from 'react'
import { Avatar, Button, Col, Divider, Menu, Row, Typography, Steps, Card, Flex, Space, Badge, Empty, Progress, Rate, Tabs, Table, Tag } from "antd"

const { Title, Text, Paragraph } = Typography

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',  
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];


const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: '1',
    label: 'Active Jobs',
    children: <>
      <Table columns={columns} dataSource={data} />
    </>,
  },
  {
    key: '2',
    label: 'Job History',
    children: 'Content of Tab Pane 2',
  },
  {
    key: '3',
    label: 'Job Posts',
    children: 'Content of Tab Pane 3',
  },
];


const AllJobs = () => {
  return (
    <div>
      <Flex align='center' justify='space-between' className='mb-12'>
        <Title level={4} className="mb-1" aria-label="name">
          All Jobs
        </Title>
        <Button type='primary'>Add New</Button>
      </Flex>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  )
}

export default AllJobs