import React from "react"
import { Button, Flex, Layout, Typography } from "antd"
import appLogo from "../../assets/common/logo.png"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { useLocation } from "react-router-dom"

const { Text, Link } = Typography
const { Header } = Layout

const LoginHeader = () => {
  const location = useLocation()
  const { navigateTo } = useRouterNavigation()
  return (
    <Header className="HeaderWrapper">
      <div className="HeaderBounds">
        <Flex justify="space-between" align="center">
          <Flex gap={48} align="center">
            <div className="AppLogoWrap">
              <img src={appLogo} alt="app logo" />
            </div>
          </Flex>
          <>
            {(location.pathname === Routes.LOGIN ||
              location.pathname === Routes.REGISTER) && (
              <Flex gap="middle" align="center">
                {location.pathname === Routes.LOGIN ? (
                  <>
                    <Text>Don’t have an account?</Text>
                    <Button onClick={() => navigateTo(Routes.REGISTER)}>
                      Sign Up
                    </Button>
                  </>
                ) : (
                  <>
                    <Text>Already have an account?</Text>
                    <Button onClick={() => navigateTo(Routes.LOGIN)}>
                      Login
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </>
        </Flex>
      </div>
    </Header>
  )
}

export default LoginHeader
